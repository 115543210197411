import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import { featureGroups } from "../data/features"
import FeatureGroup from "../components/FeatureGroup"

const PageFeatures = () => {
  return (
    <Layout>
      <PageTitle
        title="Features"
        description="Easily manage your Dispatching, Invoicing, Vehicle Inventory/Impounds, Liens, Auctions and more, all through an easy to use web based system."
      ></PageTitle>
      {featureGroups.map((featureGroup, index) => {
        return (
          <FeatureGroup
            key={index}
            featureGroup={{ ...featureGroup, index }}
          ></FeatureGroup>
        )
      })}
    </Layout>
  )
}

export default PageFeatures
