import React from "react"
import PropTypes from "prop-types"

const FeatureItem = ({ feature, className = "" }) => {
  let { name, description, icon: Icon } = feature
  return (
    <div className={`flex flex-col text-gray-300 p-6 lg:p-8 ${className}`}>
      <Icon className="text-blue-400 w-12 h-12 lg:w-16 lg:h-16 mb-3 lg:mb-4" />
      <div className="text-lg lg:text-xl text-gray-100">{name}</div>
      <div
        className="text-sm lg:text-base leading-500 mt-1"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  )
}

FeatureItem.propTypes = {
  feature: PropTypes.object,
  className: PropTypes.string,
}

export default FeatureItem
