import React, { useState } from "react"
import PropTypes from "prop-types"
import ContentBlock from "../ContentBlock"
import FeatureItem from "../FeatureItem"
import { Waypoint } from "react-waypoint"
import { useSpring, useTrail, animated, config } from "react-spring"

const FeatureGroup = ({ featureGroup, className = "" }) => {
  let { name, description, features, index } = featureGroup
  let [visible, toggle] = useState(false)

  let spring = useSpring({
    opacity: visible ? 1 : 0,
    transform: visible ? "translate3d(0,0,0)" : "translate3d(0,10%,0)",
    config: config.slow,
  })

  let trails = useTrail(features.length, {
    opacity: visible ? 1 : 0,
    transform: visible
      ? "scale(1) translate3d(0,0,0)"
      : "scale(0.3) translate3d(0,10%,0)",
    config: {
      tension: 150,
      friction: 20,
    },
  })

  return (
    <animated.div style={spring}>
      <ContentBlock
        className={`mt-4 ${
          index === 0 ? "lg:mt-8" : "mt-16 lg:mt-20"
        } ${className}`}
      >
        <div className="mb-6 lg:mb-8 max-w-3xl">
          <div className="text-gray-100 leading-400 text-2xl lg:text-3xl">
            {name}
          </div>
          <Waypoint onEnter={() => toggle(true)} />
          <div className="mt-3 text-gray-300 leading-500 text-sm lg:text-lg">
            {description}
          </div>
        </div>
        <div className="feature-list lg:-mx-8">
          {features.map((feature, index) => {
            let style = trails[index]
            return (
              <animated.div
                key={index}
                style={style}
                className="rounded bg-gray-900"
              >
                <FeatureItem feature={feature}></FeatureItem>
              </animated.div>
            )
          })}
        </div>
      </ContentBlock>
    </animated.div>
  )
}

FeatureGroup.propTypes = {
  featureGroup: PropTypes.object,
  groupIndex: PropTypes.number,
  className: PropTypes.string,
}

export default FeatureGroup
